import React, { useEffect } from "react";
import styles from "./footer.module.css";
import logoIcon from "../../assets/logowhite.png";
import { useNavigate } from "react-router";
import { Link, withRouter } from "react-router-dom";

const Footer = ({ props }) => {
  const navigate = useNavigate();

  const ScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.footer}>
      <div className={styles.footer_image}>
        <h2>
          Follow us on
          {/* <a href="#" target="_blank">
            Instagram
          </a> */}
        </h2>
        <div className={styles.footer_image}>
          <Link
            to="https://www.instagram.com/ready2gym24?igsh=dXg3eTZmMHBpbHVp"
            target="_blank"
          >
            <i
              class="fa-brands fa-square-instagram"
              style={{
                cursor: "pointer",
                fontSize: "25px",
                color: "#fff",
                marginLeft: "15px",
              }}
            ></i>
          </Link>
        </div>
        <div className={styles.footer_image}>
          <Link
            to="https://www.facebook.com/profile.php?id=61563539997890&mibextid=ZbWKwL"
            target="_blank"
          >
            <i
              class="fa-brands fa-facebook"
              style={{
                cursor: "pointer",
                fontSize: "25px",
                color: "#fff",
                marginLeft: "15px",
              }}
            ></i>
          </Link>
        </div>
        <div className={styles.footer_image}>
          <Link
            to="https://www.youtube.com/channel/UCycLPjcMOwTGZCIIMHYqdBg"
            target="_blank"
          >
            {" "}
            <i class="fa-brands fa-youtube" style={{ fontSize: "30px" }}></i>
          </Link>{" "}
        </div>
      </div>
      <div className={`${styles.footer_content} container `}>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-12">
            <div className={styles.logo_holder}>
              <img
                onClick={() => ScrollToTop()}
                src={logoIcon}
                alt="Logo Icon"
                style={{ height: 300, width: 300 }}
              />

              {/* <h3>ReadyToGym</h3> */}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-12">
          <ul style={{ paddingLeft: 0 }}>
            <li>Utility Pages</li>
            <li onClick={() => navigate("/explore")} style={{ color: "#ccc" }}>
              Fitness Centers
            </li>
            <li onClick={() => navigate("/courses")} style={{ color: "#ccc" }}>
              Courses
            </li>
            <li onClick={() => navigate("/blog")} style={{ color: "#ccc" }}>
              Blog
            </li>
            {/* <li>Password Protected</li>
            <li>Licenses</li> */}
            {/* <li onClick={() => navigate("/enquiry")} style={{ color: "#ccc" }}>
              Enquiry
            </li> */}
          </ul>
        </div>
        <div className="col-lg-3 col-md-3 col-12">
          <ul style={{ paddingLeft: 0 }}>
            <li style={{ color: "#ccc", marginBottom: "18px" }}>Quick Links</li>
            <li
              onClick={() => navigate("/terms-of-use")}
              style={{ color: "#ccc", marginBottom: "18px", cursor: "pointer" }}
            >
              Terms Of Use
            </li>
            <li
              onClick={() => navigate("/privacy-policy")}
              style={{ color: "#ccc", marginBottom: "18px", cursor: "pointer" }}
            >
              Privacy
            </li>
            <li
              onClick={() => navigate("/refund-policy")}
              style={{ color: "#ccc", marginBottom: "18px", cursor: "pointer" }}
            >
              Refund Policy
            </li>
            {/* <li>Password Protected</li>
            <li>Licenses</li> */}
            {/* <li onClick={() => navigate("/enquiry")} style={{ color: "#ccc" }}>
              Enquiry
            </li> */}
          </ul>
        </div>

        {/* <div>
          <h3>Newsletter</h3>
          <input type="text" placeholder="Enter Your Email" />
          <button>Subscribe</button>
          <div className={styles.footer_icons}>
            <a href="#" target="_blank">
              <i className="fa-brands fa-linkedin"></i>
            </a>
            <a href="#" target="_blank">
              <i className="fa-brands fa-github"></i>
            </a>
          </div>
        </div> */}
        <div className="col-lg-3 col-md-3 col-12">
          <ul
            style={{
              paddingRight: "20px",
              marginRight: "30px",

              paddingLeft: 0,
            }}
          >
            <li
              style={{
                color: "#ccc",
                paddingBottom: "35px",
                paddingRight: "20px",
                marginRight: "30px",
              }}
            >
              Catch Us
            </li>
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                class="fa-solid fa-mobile-screen"
                style={{
                  paddingRight: "10px",
                  height: "20px",
                  width: "25px",
                  color: "#fff",
                }}
              ></i>
              <li style={{ color: "#ccc" }}> +91-70426-06002</li>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <i
                class="fa-regular fa-envelope"
                style={{
                  paddingRight: "10px",
                  height: "20px",
                  width: "25px",
                  color: "#fff",
                }}
              ></i>
              <li style={{ color: "#ccc" }}>infoready2gym@gmail.com</li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
