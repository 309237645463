import React from "react";

const Refund = () => {
  return (
    <div
      className="container"
      style={{
        paddingBottom: "25px",
        marginTop: "225px",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <h1 className="text-center mt-5" style={{ textAlign: "center" }}>
        Ready2Gym <br />
      </h1>
      <h3 className="text-center mt-5" style={{ textAlign: "center" }}>
        GSTIN - 03DLXPS4234R1Z3 <br />
      </h3>
      <h2
        className="text-center mt-5 mb-5"
        style={{ color: "#6a73fa", textAlign: "center" }}
      >
        Refund Policy
      </h2>
      <p>
        <strong style={{ fontSize: "14px" }}> 1. Overview</strong>
      </p>
      <p style={{ fontSize: "14px" }}>
        This Refund Policy governs the terms under which refunds are issued by
        www.ready2gym.com for services provided through our platform. Please
        read this policy carefully to understand your rights and
        responsibilities regarding refunds.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>2. Subscription Fees</strong>
      </p>
      <p style={{ fontSize: "14px" }}>
        Eligibility for Refunds Monthly Subscriptions - Monthly subscription
        fees are eligible for a refund within 7 days of the initial subscription
        date if the user is dissatisfied with the service provided. No refunds
        will be issued after this period. Annual Subscriptions - Annual
        subscription fees are eligible for a prorated refund within 30 days of
        the initial subscription date. The refund amount will be calculated
        based on the remaining months left in the subscription period.
        Non-Refundable Items Featured Listings - Fees paid for featured listings
        or promotional services are non-refundable once the service has been
        rendered or the listing has been published on www.ready2gym.com.
        One-Time Payments - Payments made for one-time services or additional
        features that have already been provided are non-refundable.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>3. Requesting a Refund</strong>
      </p>
      <p style={{ fontSize: "14px" }}>
        Procedure Submitting a Request - To request a refund, users must contact
        our support team at infoready2gym@gmail.com within the specified refund
        period. Please provide your account details and the reason for your
        refund request. Processing Time - Refunds will be processed within 7-14
        business days of receiving a valid refund request. The refund will be
        issued to the original payment method used for the transaction.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>4. Dispute Resolution</strong>
      </p>
      <p style={{ fontSize: "14px" }}>
        Disputes - If a user disagrees with our refund decision, they may
        escalate the matter by contacting our support team. We will review the
        request and provide a final decision within a reasonable timeframe.{" "}
      </p>
      <p style={{ fontSize: "14px" }}>
        {" "}
        <strong>
          Contact Information For any questions or concerns regarding our Refund
          Policy, please contact us at: -{" "}
        </strong>
      </p>
      Email: infoready2gym@gmail.com <br /> Address - 916A, Global City, Sector
      - 124, <br /> Kharar, Mohali, <br /> Punjab-140301.
      <p style={{ fontSize: "14px" }}>
        {" "}
        This Refund Policy ensures fairness and transparency in managing refund
        requests for our services. For further assistance, please reach out to
        us at infoready2gym@gmail.com. SEO Meta Description: Understand the
        refund policy for www.ready2gym.com, covering eligibility, procedures,
        and non-refundable items. Ensure clarity and transparency in managing
        subscription refunds.
      </p>
    </div>
  );
};

export default Refund;
