import React from "react";

const Privacy = () => {
  return (
    <div
      className="container"
      style={{
        paddingBottom: "25px",
        marginTop: "225px",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <h1 className="text-center mt-5" style={{ textAlign: "center" }}>
        Ready2Gym
      </h1>
      <h3 className="text-center mt-5" style={{ textAlign: "center" }}>
        GSTIN - 03DLXPS4234R1Z3 <br />
      </h3>
      <h2
        className="text-center mt-5 mb-5"
        style={{ color: "#6a73fa", textAlign: "center" }}
      >
        Privacy Policy{" "}
      </h2>
      <h6 style={{ fontSize: "16px" }}>Effective Date: 08-07-2024</h6>
      <p>
        <strong style={{ fontSize: "14px" }}>Introduction</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        Welcome to Ready2Gym, an online platform operated by P. D. And Sons for
        promoting local gyms, health & fitness centers, and yoga centers. Your
        privacy is of utmost importance to us. This Privacy Policy outlines how
        we collect, use, disclose, and protect your personal information when
        you visit our website www.ready2gym.com.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}> 2. Information We Collect</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        Personal Information: When you register on our website, we may collect
        personal details such as your name, email address, phone number, and
        address. Non-Personal Information: We may collect non-personal
        information about your visit to our website, such as your IP address,
        browser type, referring/exit pages, and operating system.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>
          3. How We Use Your Information
        </strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        We use the information we collect to: - Provide and maintain our
        services. - Personalize and improve your experience on our website. -
        Communicate with you regarding our services, promotions, and updates. -
        Process transactions and send related information. - Monitor and analyze
        trends, usage, and activities on our website.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>
          4. Disclosure of Your Information
        </strong>{" "}
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        We may disclose your information: - To third-party service providers who
        help us operate our website and services. - To comply with legal
        obligations, respond to legal requests, and protect our rights and
        safety. - In connection with a business transaction such as a merger,
        acquisition, or asset sale.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>
          5. Security of Your Information
        </strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        We implement appropriate security measures to protect your personal
        information from unauthorized access, alteration, disclosure, or
        destruction. However, no method of transmission over the Internet or
        electronic storage is 100% secure.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>
          6. Cookies and Tracking Technologies
        </strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        We use cookies and similar tracking technologies to enhance your
        experience on our website. You can control the use of cookies through
        your browser settings.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}> 7. Third-Party Links</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        Our website may contain links to third-party websites. We are not
        responsible for the privacy practices or the content of these websites.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>8. Your Rights</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        You have the right to: - Access, update, or delete your personal
        information. - Object to the processing of your personal information. -
        Withdraw your consent at any time. To exercise these rights, please
        contact us at infoready2gym@gmail.com
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>
          {" "}
          9. Changes to This Privacy Policy
        </strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on our website. Your
        continued use of the website after the changes take effect will
        constitute your acceptance of the revised policy.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>10. Contact Us</strong>{" "}
      </p>
      <p style={{ fontSize: "13px" }}>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at: P. D. And Sons <br />
        Email: infoready2gym@gmail.com <br /> Phone: 7042606002 <br /> <br />{" "}
        Address - 916A, Global City, Sector - 124, <br /> Kharar, Mohali, <br />{" "}
        Punjab-140301.
        <br /> By using our website, you consent to the terms of this Privacy
        Policy
      </p>
    </div>
  );
};

export default Privacy;
