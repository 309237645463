import React from "react";
import "./term.module.css";
const Terms = () => {
  return (
    <div
      className="container"
      style={{
        paddingBottom: "25px",
        marginTop: "175px",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <h1 className="text-center mt-5" style={{ textAlign: "center" }}>
        Ready2Gym{" "}
      </h1>
      <h3 className="text-center mt-5" style={{ textAlign: "center" }}>
        GSTIN - 03DLXPS4234R1Z3 <br />
      </h3>
      <h2
        className="text-center mt-5 mb-5"
        style={{ color: "#6a73fa", textAlign: "center" }}
      >
        Terms of Service
      </h2>
      <p className="para">
        <strong className="para" style={{ fontSize: "20px" }}>
          1. Introduction
        </strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        Overview of the Terms of Service The Terms of Service for
        www.ready2gym.com outline the rules and regulations for using our
        platform. By accessing our website, you agree to comply with these
        terms. This document provides detailed information about the services we
        offer, user responsibilities, and legal guidelines to ensure a safe and
        effective environment for all users. Importance and Scope These Terms of
        Service are crucial for establishing a clear understanding between
        www.ready2gym.com and its users. They cover various aspects of our
        platform, including user responsibilities, prohibited activities,
        content guidelines, and payment terms. Understanding these terms is
        essential for using our services effectively and legally.
      </p>
      <p style={{ fontSize: "13px", marginBottom: "10px" }}>
        {" "}
        Contact Information For any questions or concerns regarding these terms,
      </p>{" "}
      please contact us at: -<br />
      Email: infoready2gym@gmail.com
      <br />
      Address - 916A, Global City, Sector - 124, <br /> Kharar, Mohali, <br />{" "}
      Punjab-140301.
      <p style={{ marginTop: "10px" }}>
        <strong style={{ fontSize: "14px" }}>2. Definitions</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong>Key Terms and Definitions - </strong>User: Any individual or
        entity accessing www.ready2gym.com. - Service: The listing and
        promotional services provided by www.ready2gym.com. - Content: All text,
        images, and other materials posted on www.ready2gym.com. - Account: The
        personal profile created by a user to access the services.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>3. Acceptance of Terms</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        User Agreement By using www.ready2gym.com, you agree to adhere to these
        Terms of Service. This agreement is binding and applies to all users of
        the platform. Acceptance Criteria Acceptance of these terms is a
        prerequisite for accessing our services. If you do not agree with any
        part of these terms, you must refrain from using the website.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>4. Services Provided</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong> Listing Services </strong> www.ready2gym.com provides a
        platform for local gyms, health and fitness centers, and yoga centers to
        list their services. This includes detailed profiles, service
        descriptions, and contact information. Promotional Services In addition
        to listing services, www.ready2gym.com offers promotional services to
        enhance the visibility of listed entities. This may include featured
        listings, social media promotions, and email marketing. Subscription
        Plans Our platform offers various subscription plans to meet the needs
        of different users. Each plan comes with specific features and benefits,
        designed to maximize exposure and engagement. Additional Features
        Additional features may include customer reviews, booking systems, and
        personalized recommendations. These features aim to provide a
        comprehensive and user-friendly experience.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>5. User Responsibilities</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong> Accurate Information</strong>Users are responsible for
        providing accurate and up-to-date information when creating accounts and
        listing services. Any false or misleading information may result in the
        suspension or termination of services. Compliance with Laws Users must
        comply with all applicable laws and regulations when using
        www.ready2gym.com. This includes, but is not limited to, consumer
        protection laws, data privacy laws, and intellectual property laws.
        Account Security Users are responsible for maintaining the security of
        their accounts. This includes safeguarding login credentials and
        promptly reporting any unauthorized access.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>6. Prohibited Activities</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong>Unauthorized</strong> Use Unauthorized use of the platform,
        including hacking, data mining, or any form of automated access, is
        strictly prohibited. Misrepresentation Users must not misrepresent
        themselves or their services. This includes false advertising, fake
        reviews, and any form of deceptive conduct. Illegal Activities Any
        activity that violates local, state, or federal laws is strictly
        prohibited on www.ready2gym.com. This includes, but is not limited to,
        fraudulent activities, harassment, and distribution of illegal content.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>7. Content Guidelines</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong>User-Generated Content</strong> Users may post content related
        to their services. All content must comply with our guidelines and be
        relevant to the health and fitness industry. Content Ownership Users
        retain ownership of their content but grant www.ready2gym.com a
        non-exclusive license to use, display, and distribute the content on the
        platform. Content Removal www.ready2gym.com reserves the right to remove
        any content that violates these terms or is deemed inappropriate for the
        platform.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>8. Intellectual Property</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong> Website Content </strong>All content on www.ready2gym.com,
        including text, graphics, logos, and images, is the property of
        www.ready2gym.com or its content suppliers and is protected by
        intellectual property laws. Trademarks and Logos All trademarks, service
        marks, and logos used on www.ready2gym.com are the property of their
        respective owners. Unauthorized use is prohibited. Copyright
        Infringement www.ready2gym.com respects the intellectual property rights
        of others. If you believe your work has been copied in a way that
        constitutes copyright infringement, please contact us immediately.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>9. Privacy Policy</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong> Data Collection </strong>www.ready2gym.com collects personal
        data from users to provide and improve services. This includes contact
        information, usage data, and payment details. Data Use and Sharing
        Collected data is used for service delivery, user support, and marketing
        purposes. We do not sell or share personal data with third parties
        without user consent, except as required by law. User Rights Users have
        the right to access, modify, or delete their personal data. Requests can
        be made through our contact email.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>10. Payment Terms</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong> Subscription </strong>Fees Subscription fees are required for
        accessing premium services on www.ready2gym.com. The fee structure is
        detailed on our pricing page. Payment Methods We accept various payment
        methods, including credit cards and online payment systems. All
        transactions are secured and encrypted. Refund Policy Refunds are issued
        under specific circumstances, as outlined in our refund policy. Users
        must request refunds within the stipulated period.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>11. Third-Party Links</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong> External Links</strong> www.ready2gym.com may contain links to
        third-party websites. We are not responsible for the content or
        practices of these external sites. Liability for Third-Party Content
        www.ready2gym.com does not endorse or assume liability for third-party
        content. Users access these links at their own risk.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>
          12. Disclaimers and Limitations of Liability
        </strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong> General Disclaimers</strong> The services on www.ready2gym.com
        are provided "as is" without warranties of any kind. We do not guarantee
        the accuracy or completeness of information on the platform. Limitation
        of Liability www.ready2gym.com is not liable for any damages arising
        from the use of the platform, including direct, indirect, incidental, or
        consequential damages.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>13. Indemnification</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong> User Indemnity </strong>Users agree to indemnify and hold
        harmless www.ready2gym.com from any claims, damages, or expenses arising
        from their use of the platform or violation of these terms. Scope of
        Indemnification Indemnification covers legal fees, losses, and other
        costs incurred by www.ready2gym.com as a result of user actions.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>14. Modification of Terms</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong> Right to Modify </strong>www.ready2gym.com reserves the right
        to modify these terms at any time. Changes will be effective immediately
        upon posting on the website. Notification of Changes Users will be
        notified of significant changes to these terms via email or through a
        prominent notice on the platform.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>
          15. Termination of Services
        </strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong> Termination </strong>by User Users may terminate their accounts
        at any time by contacting us. Termination will result in the loss of
        access to services. Termination by www.ready2gym.com www.ready2gym.com
        reserves the right to terminate user accounts for violations of these
        terms or any other reason deemed necessary.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>16. Governing Law</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong> Jurisdiction </strong>These terms are governed by the laws of
        the state of Punjab, India. Any disputes will be resolved in the courts
        of Mohali. Dispute Resolution Users agree to resolve any disputes
        through binding arbitration or small claims court, depending on the
        nature of the claim.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>17. Contact Information</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong> Support </strong>Contact For support or inquiries, users can
        reach us at infoready2gym@gmail.com.
        <br />
        Address and Email - Email: infoready2gym@gmail.com <br />
        <br /> Address - 916A, Global City, Sector - 124, <br /> Kharar, Mohali,{" "}
        <br /> Punjab-140301.
      </p>
      <p>
        <strong style={{ fontSize: "14px" }}>18. FAQs</strong>
      </p>
      <p style={{ fontSize: "13px" }}>
        {" "}
        <strong> Common Questions and Answers</strong>{" "}
        <p>
          {" "}
          <strong style={{ fontSize: "14px" }}>
            {" "}
            Q1: How do I create an account on www.ready2gym.com?{" "}
          </strong>
        </p>
        <p style={{ fontSize: "12px" }}>
          A: To create an account, click on the "Sign Up" button on the homepage
          and follow the instructions.{" "}
        </p>
        <p>
          {" "}
          <strong style={{ fontSize: "14px" }}>
            {" "}
            Q2: What types of services
          </strong>
        </p>
        <p>
          {" "}
          <strong style={{ fontSize: "12px" }}>
            {" "}
            can I list on www.ready2gym.com? A: You can list local gyms, health
            and fitness centers, and yoga centers.{" "}
          </strong>
        </p>
        <p>
          {" "}
          <strong style={{ fontSize: "14px" }}>
            Q3: How do I upgrade my subscription plan?{" "}
          </strong>{" "}
        </p>
        <p>
          {" "}
          <strong style={{ fontSize: "12px" }}>
            {" "}
            A: To upgrade, go to your account settings and select the "Upgrade
            Plan" option.
          </strong>
        </p>
        <p>
          {" "}
          <strong style={{ fontSize: "14px" }}>
            {" "}
            Q4: What should I do if I encounter a technical issue?{" "}
          </strong>{" "}
        </p>
        <br />
        <p>
          {" "}
          For technical issues, please contact our support team at{" "}
          <strong>infoready2gym@gmail.com. </strong>{" "}
        </p>
        <strong style={{ fontSize: "14px" }}>
          <br /> Q5: Can I cancel mysubscription at any time?{" "}
        </strong>
      </p>{" "}
      <p>
        {" "}
        <strong style={{ fontSize: "12px" }}>
          A: Yes, you can cancel your subscription at any time through your
          account settings.
        </strong>
      </p>{" "}
      <p>
        {" "}
        <strong style={{ fontSize: "14px" }}>
          {" "}
          Q6: How is my personal data protected?{" "}
        </strong>
      </p>{" "}
      <p>
        {" "}
        <strong style={{ fontSize: "12px" }}>
          {" "}
          A: We use industry-standard security measures to protect your data.
          For more details, refer to our Privacy Policy. SEO Meta Description:
          Discover the comprehensive terms of service for www.ready2gym.com,
          outlining user responsibilities, prohibited activities, payment terms,
          and more. Ensure a safe and effective experience on our platform.
        </strong>
      </p>
    </div>
  );
};

export default Terms;
